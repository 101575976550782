// Import libraries
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faSms } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Component styles
const StyledHeader = styled.header`
    display: grid;
    grid-template-columns: max-content 1fr max-content max-content;
    grid-template-rows: 15rem; /* Row height is equal to StyledBanner height calculation in index.js */
    grid-column-gap: 5rem;
    align-items: center;
    justify-items: center;
    padding: 0 var(--padding-right) 0 var(--padding-left);

    @media only screen and (max-width: 800px) {
        grid-template-columns: repeat(3, max-content);
        justify-content: center;
    }
    
    @media only screen and (max-width: 800px) {
        & .link--logo {
            display: none;
        }
    }

    & .link:link,
    & .link:visited,
    & .link:hover,
    & .link:focus,
    & .link:active {
        color: currentColor;
        text-decoration: none;
        outline: 0;
    }

    & .link.link--logo {
        font-size: 5rem;
        font-weight: bold;
        color: var(--color-secondary);
    }
`;

const StyledHeaderIcon = styled.div`
    color: var(--color-primary);
    cursor: pointer;
    transition: color .25s ease-in-out,
                border-color .25s ease-in-out;

    & .link {
        display: grid;
        grid-template-columns: 6rem max-content;
        grid-column-gap: 2rem;
        align-items: center;
        justify-items: center;

        @media only screen and (max-width: 350px) {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, min-content);
            grid-row-gap: 2rem;
        }
        
        & .icon {
            display: grid;
            align-items: center;
            justify-items: center;
            width: 6rem;
            height: 6rem;
            border: 5px solid var(--color-primary);
            border-radius: 50%;
            transition: border-color .25s ease-in-out;
        }

        & span {
            font-size: 2rem;
            font-weight: bold;
        }
    }

    & .link,
    & .link:visited,
    & .link:hover,
    & .link:focus,
    & .link:active {
        color: currentColor;
        text-decoration: none;
        outline: 0;
    }
    
    & .icon {
        font-size: 2.5rem;
        color: currentColor;
    }

    &:hover {
        color: var(--color-primary);

        & .link .icon {
            border-color: var(--color-primary);
        }
    }
`;


// Create component
const Header = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    companyName
                    phoneNumber
                }
            }
        }
    `);


    return (
        <React.Fragment>
            <StyledHeader className="header">
                <StyledHeaderIcon>
                    <a href={`tel:${data.site.siteMetadata.phoneNumber}`} className="link" id="call-header" aria-label="Phone us">
                        <div className="icon">
                            <FontAwesomeIcon icon={faPhoneAlt} />
                        </div>
                        <span>{data.site.siteMetadata.phoneNumber}</span>
                    </a>
                </StyledHeaderIcon>

                <Link to="/" className="link link--logo">{data.site.siteMetadata.companyName}</Link>

                <StyledHeaderIcon>
                    <a href={`sms:${data.site.siteMetadata.phoneNumber}`} className="link" aria-label="SMS us">
                        <div className="icon">
                            <FontAwesomeIcon icon={faSms} />
                        </div>
                    </a>
                </StyledHeaderIcon>

                <StyledHeaderIcon>
                    <a href="#contact" className="link" aria-label="Contact us">
                        <div className="icon">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                    </a>
                </StyledHeaderIcon>
            </StyledHeader>
        </React.Fragment>
    );
};


// Export component by default
export default Header;