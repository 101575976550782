// Import libraries
import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Component styles
const StyledFooter = styled.footer`
    display: grid;
    grid-template-columns: repeat(5, max-content);
    grid-column-gap: 3rem;
    align-items: center;
    justify-content: center;
    padding: 5rem var(--padding-right) 5rem var(--padding-left);
    background-color: #262626;
    position: relative;

    @media only screen and (max-width: 500px) {
        padding: 7rem var(--padding-right) 7rem var(--padding-left);
        grid-column-gap: 1.5rem;
    }
    
    & .footer-scroll-to-top-button {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 60px;
        height: 60px;
        background-color: #262626;
        color: rgba(255, 255, 255, .6);
        transition: color .25s ease-in-out;
        cursor: pointer;
        
        &:hover {
            color: rgba(255, 255, 255, .8);
        }

        & .icon-container {
            width: 60px;
            height: 60px;
            line-height: 60px;
            font-size: 2rem;
            transform: rotate(-45deg);
            display: block;
            text-align: center;
        }

        &:hover .icon-container {
            transform: rotate(-45deg) translateY(-5px);
        }
    }

    & .footer-item {
        color: rgba(255, 255, 255, .6);
        text-transform: uppercase;
        font-size: 1.3rem;

        &-link:link,
        &-link:visited {
            color: currentColor;
            text-decoration: none;
            outline: 0;
            transition: color .25s ease;
        }

        &-link:hover,
        &-link:focus {
            text-decoration: underline;
            color: rgba(255, 255, 255, .8);
            outline: 0;
        }

        &-link:active {
            text-decoration: none;
            color: currentColor;
        }
    }

    & .footer-divider {
        color: rgba(255, 255, 255, .6);
    }
`;


// Create component
const Footer = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    companyName
                }
            }
        }
    `);


    const handleScrollToTop = () => {
        // Scroll to top of page
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };


    return (
        <StyledFooter className="footer">
            <div className="footer-scroll-to-top-button" onClick={handleScrollToTop}>
                <div className="icon-container">
                    <FontAwesomeIcon icon={faArrowUp} />
                </div>
            </div>

            <span className="footer-item">&copy; {data.site.siteMetadata.companyName} {new Date().getFullYear()}</span>
            <span className="footer-divider">&middot;</span>
            <span className="footer-item"><Link to="/disclamer" className="footer-item-link">Disclamer</Link></span>
            <span className="footer-divider">&middot;</span>
            <span className="footer-item">Website:&nbsp;&nbsp;<a href="https://bou-media.com" target="_blank" rel="noopener noreferrer" className="footer-item-link">BouMedia</a></span>
        </StyledFooter>
    );
};


// Export component by default
export default Footer;