// Import libraries
import React from 'react';
import styled from 'styled-components';


// Import global styles
import GlobalStyle from '../styles/global';


// Import component
import Layout from '../layout/Layout';
import SEO from '../layout/SEO';


// Component styles
const StyledContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(2, max-content);
    grid-row-gap: 2rem;
    padding: 10rem var(--padding-right) 10rem var(--padding-left);
`;


// Create component
const DisclamerPage = () => {
    return (
        <React.Fragment>
            <GlobalStyle />
            <SEO title="Disclamer" />
            <Layout>
                <StyledContainer>
                    <h1>Disclamer</h1>

                    <p>The disclamer goes here.</p>
                </StyledContainer>
            </Layout>
        </React.Fragment>
    );
};


// Export component by default
export default DisclamerPage;